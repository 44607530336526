<template>
    <table-container>
        <data-iterator ref="iterator" :api="api" :filters.sync="filters">
            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-select
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <delete-action
                            :entity-name="item.name"
                            :entity-id="item.id"
                            :api="api"
                            strong
                            @deleted="onEntityDeleted"
                            @failed="onEntityDeleteFailed"
                        ></delete-action>
                    </template>
                </d-table>
            </template>

            <!-- batch actions -->
            <template v-slot:batchActions>
                <delete-batch-action
                    :selected-ids="selectedIds"
                    :api="api"
                    strong
                    @finished="onBatchDeleteFinished"
                    @finishedWithErrors="onBatchDeleteFinishedWithErrors"
                    @canceled="onBatchDeleteCanceled"
                ></delete-batch-action>
            </template>
        </data-iterator>
    </table-container>
</template>

<script>
import Headers from '@/components/pages/customExportCategory/mixins/Headers'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import RestApiType from '@/api/RestApiType'
import routeType from '@/router/routeType'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, Headers],

    data() {
        return {
            api: RestApiType.CUSTOM_EXPORT_CATEGORY,
        }
    },

    computed: {
        tableName() {
            return this.$t('labels.custom_export')
        },
    },

    methods: {
        getEditLink(item) {
            return {
                name: routeType.CUSTOM_EXPORT_CATEGORY_EDIT,
                params: { id: item.id },
            }
        },
    },
}
</script>
