<template>
    <div>
        <page-title>{{ $t('custom_export_category.title') }}</page-title>
        <custom-export-category-table ref="table">
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.CUSTOM_EXPORT_CATEGORY_CREATE"
                ></create-button>
            </template>
        </custom-export-category-table>
    </div>
</template>
<script>
import PageTitle from '@/components/layout/components/pageTitle.vue'
import CustomExportCategoryTable from '@/components/pages/customExportCategory/Table.vue'
import CreateButton from '@/components/form/Actions/CreateButton.vue'
import routeType from '@/router/routeType'

export default {
    components: {
        CreateButton,
        PageTitle,
        CustomExportCategoryTable,
    },
    data() {
        return {
            routeType,
        }
    },
}
</script>
